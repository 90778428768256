import config from "@/config";
import moment from "moment";
import store from "@/store";
import router from "@/router";
import axios from "axios";
import { cloneDeep, isEmpty, orderBy, values } from "lodash-es";
import * as types from "@/store/mutation-types";
import { jwtDecode as decode } from "jwt-decode";
import * as analytics from "@/services/analytics";
import modules from "@/utils/enums/cmsModules";
import itemTypes from "@/utils/enums/itemTypes";

const MELIA_KEY = "d2e0ac2976be6e905ba66a75e255f587";
const RIU_KEY = "b431458224bfa3be4082f0571bf8a3e1";

const PWA_APP_ID = 76;
const MELIA_APP_ID = 91;
const RIU_APP_ID = 109;
const HYATTPWA_APP_ID = 116;

const getImageUrl = (imgId, original, size) => {
  original = original ? "" : "_ld";
  if (size) {
    original = size;
  }
  return `${config.imageHost}/${imgId}/${imgId + original}`;
};

const filterNotificationCount = notifications => {
  const filtered = notifications.filter(notification => {
    return !notification.alreadyShown || notification.alreadyShown === false || notification.type != "survey";
  });
  return filtered ? filtered.length : 0;
};

const checkEmptyTranslations = obj => {
  if (!obj) {
    return true;
  }
  try {
    return values(obj).every(isEmpty);
  } catch (error) {
    return false;
  }
};

const getPDFUrl = pdfId => {
  return config.pdfHost + "/" + pdfId + "/" + pdfId;
};

const getHTMLContentUrl = htmlContent => {
  return "<!DOCTYPE html><html>" + htmlContent + "</html>";
};

const getImageUrlField = (imgId, imgUrl, original, size) => {
  if (imgUrl != null && imgUrl.length > 0 && imgUrl[0] && imgUrl[0].includes("http")) {
    return imgUrl[0];
  } else if (imgId != null && imgId.length > 0) {
    return getImageUrl(imgId[0], original, size);
  } else {
    return "";
  }
};

const translateEnglishOrDefaultForced = function (translatable) {
  return translate(translatable, { locale: "en" }, true);
};
const translate = function (translatable, user, forcingLocale) {
  let locale = "en";
  if (forcingLocale && user?.locale) {
    locale = user.locale;
  } else if (!store.state.isLobby) {
    locale = window.navigator.language.substring(0, 2);
  } else if (user && user.locale) {
    locale = user.locale;
  }
  if (store.state.interfaceLanguage) {
    locale = store.state.interfaceLanguage;
  }
  if (!locale) {
    locale = "en";
  }
  const permittedLanguages =
    store.state.establishment && store.state.establishment.languages
      ? orderBy(store.state.establishment.languages, ["languageOrder"], ["asc"])
      : null;
  if (translatable) {
    if (permittedLanguages) {
      const localeIsPermitted = permittedLanguages.filter(language => {
        return language.languageCode === locale;
      });
      if (localeIsPermitted.length > 0 && translatable[locale]) {
        return translatable[locale];
      }
      for (let index = 0; index < permittedLanguages.length; index++) {
        const permittedLanguage = permittedLanguages[index];
        if (permittedLanguage.languageCode.length > 0 && translatable[permittedLanguage.languageCode]) {
          return translatable[permittedLanguage.languageCode];
        }
      }
      return " ";
    } else {
      if (translatable[locale]) {
        return translatable[locale];
      } else if (translatable["en"]) {
        return translatable["en"];
      } else {
        return Object.values(translatable)[0];
      }
    }
  } else {
    return " ";
  }
};

const getStringNoEmpty = function (strings, strKey, user) {
  if (getString(strings, strKey, user).trim() == 0) {
    return false;
  } else {
    return true;
  }
};

const getString = function (strings, strKey, user) {
  const str = strings?.find(string => string.strKey === strKey);
  if (!str) {
    return strKey;
  }

  const userCopy = cloneDeep(user);

  if (!userCopy.locale) {
    userCopy.locale = window.navigator.language.substring(0, 2);
  }
  if (store.state.interfaceLanguage) {
    userCopy.locale = store.state.interfaceLanguage;
  }

  const custom = str.custom;
  const customChain = str.customChain;
  const permittedLanguages = store.state.establishment?.languages ?? null;
  const permitted =
    permittedLanguages?.find(language => {
      return language.languageCode === userCopy.locale.split("_")[0];
    }) ?? false;
  const chain = store.state.establishment?.commercialEntity ?? 0;

  if (
    custom &&
    custom[userCopy.establishmentId] &&
    userCopy.locale &&
    custom[userCopy.establishmentId][userCopy.locale.split("_")[0]]
  ) {
    return translate(custom[userCopy.establishmentId], userCopy);
  }

  if (custom && custom[userCopy.establishmentId] && !permitted) {
    return translate(custom[userCopy.establishmentId], userCopy);
  }

  if (
    customChain &&
    chain &&
    customChain[chain] &&
    userCopy.locale &&
    customChain[chain][userCopy.locale.split("_")[0]]
  ) {
    return translate(customChain[chain], userCopy);
  }

  if (customChain && chain && customChain[chain] && !permitted) {
    const customTranslation = translate(customChain[chain], userCopy);
    if (customTranslation && customTranslation != "") {
      return translate(customChain[chain], userCopy);
    } else {
      return translate(str.strValues, userCopy);
    }
  }

  if (str.strValues) {
    return translate(str.strValues, userCopy);
  }
};

const getItemUrl = function (
  itemType,
  itemId,
  serviceTypeId,
  userData,
  cmsModule,
  serviceId,
  isLobby,
  isDesktop,
  isTV,
  calendarType
) {
  if (itemType === 1) {
    return getModuleUrl(itemId, cmsModule, userData, serviceTypeId, isLobby, isDesktop, isTV);
  }

  if (itemType === 2) {
    return getCategoryUrl(itemId, cmsModule, userData, serviceTypeId, null, isLobby, isDesktop, isTV, calendarType);
  }

  if (itemType === 3) {
    return getServiceUrl(itemId, serviceTypeId, userData, isLobby, isDesktop, isTV);
  }

  if (itemType === 5) {
    return getServiceUrl(serviceId, serviceTypeId, userData, isLobby, isDesktop, isTV, true, itemId);
  }

  if (itemType === 11) {
    return getServiceUrl(serviceId, serviceTypeId, userData, isLobby, isDesktop, isTV);
  }

  if (itemType === 13) {
    return getCategoryUrl(
      itemId,
      cmsModule,
      userData,
      serviceTypeId,
      serviceId,
      isLobby,
      isDesktop,
      isTV,
      calendarType
    );
  }

  if (itemType === 16) {
    return `/booking-form/${serviceId}`;
  }

  if (itemType === 14) {
    return getServiceItemUrl(itemType, itemId, cmsModule, userData, serviceTypeId, serviceId, isLobby, isDesktop, isTV);
  }

  if (cmsModule === modules.MODULE_BOOKING_SPACES_MULTI_ESTABLISHMENT) {
    return getMultiEstablishmentModule(itemId);
  }

  if (itemType === itemTypes.ITEM_TYPE_CATALOGUE_V2) {
    return `/service-catalogue-v2/${getServiceType(serviceTypeId)}/${serviceId}/catalogue/${itemId}`;
  }
};

const getServiceItemUrl = function (
  itemType,
  itemId,
  cmsModule,
  userData,
  serviceTypeId,
  serviceId,
  isLobby,
  isDesktop,
  isTV
) {
  return (
    (isDesktop ? (isTV ? "/tv" : "/desktop") : isLobby ? "/lobby" : "") +
    "/item-detail/" +
    itemType +
    "/" +
    getServiceType(serviceTypeId) +
    "/" +
    serviceId +
    "/" +
    itemId
  );
};

const getMultiEstablishmentModule = function (itemId) {
  return `/service-list/multi-establishment/${getServiceType(itemId)}`;
};

const getModuleUrl = function (itemId, cmsModule, userData, serviceTypeId, isLobby, isDesktop, isTV) {
  let url = "";
  switch (cmsModule) {
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 13:
    case 18:
    case 30:
    case 43:
    case 83:
    case 86:
    case 87:
    case 88:
    case 90:
    case 107:
    case 111:
    case 91:
    case 98:
    case 99:
    case 100:
    case 106:
      url = "/service-list/" + getServiceType(serviceTypeId);
      break;
    case 10:
    case 84:
      url = "/calendar-list/" + getServiceType(serviceTypeId);
      break;
    case 11:
      url = "/shows";
      break;
    case 12:
      url = "/my-events";
      break;
    case 48:
      url = "/map";
      break;
    case 101:
    case 49:
    case 73:
    case 74:
      url = "/requests/3/" + getServiceType(serviceTypeId);
      break;
    case 63:
      url = "/chat";
      break;
    case 70:
      url = "/weather";
      break;
    case 72:
      url = "/checkin";
      break;
    case 77:
    case 78:
    case 79:
      url = "/external-services-list/" + cmsModule;
      break;
    case 95:
      url = "/smart-search";
      break;
    case 96:
      url = "/grouping_map/" + getServiceType(serviceTypeId);
      break;

    case 105:
      url = "/service-list/" + getServiceType(serviceTypeId);
      break;
    case 108:
      url = "/directory";
      break;
    case 110:
      url = "/city-guide";
      break;
    case 112:
      url = "/environment";
      break;
    case 115:
      url = `/service-list/multi-establishment/list`;
      break;
  }

  url = checkLobbyOrDesktopForURL(url, isLobby, isDesktop, isTV);

  return url;
};

const getCategoryUrl = function (
  itemId,
  cmsModule,
  userData,
  serviceTypeId,
  serviceId,
  isLobby,
  isDesktop,
  isTV,
  calendarType
) {
  let url = "";
  switch (cmsModule) {
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 18:
    case 83:
    case 86:
    case 87:
    case 88:
    case 90:
    case 91:
    case 96:
    case 98:
    case 99:
    case 100:
    case 105:
    case 106:
    case 107:
    case 111:
    case 115:
      url = `/service-list/${getServiceType(serviceTypeId)}/${itemId}`;
      break;
    case 84:
      url = getEventCalendarRedirection(itemId, serviceTypeId, calendarType);
      break;
    case 10:
      url = `/activities/${itemId}/${getServiceType(serviceTypeId)}`;
      break;
    case 3:
    case 13:
    case 30:
    case 43:
      url = `/item-list/${serviceId}/${itemId}/${getServiceType(serviceTypeId)}`;
  }

  url = checkLobbyOrDesktopForURL(url, isLobby, isDesktop, isTV);

  return url;
};

const getEventCalendarRedirection = function (itemId, serviceTypeId, calendarType) {
  const serviceType = getServiceType(serviceTypeId);
  let showsScreen = false;

  let typeChecked = false;
  if (calendarType && calendarType != -1) {
    typeChecked = true;
    if (calendarType == 6 || calendarType == 7 || calendarType == 1) {
      showsScreen = true; //nights & dresscode, (allDayActivities is in case)
    }
  } else {
    const categories = store.state.categories;
    if (categories && categories[serviceType]) {
      categories[serviceType].forEach(category => {
        if (category.id == itemId) {
          if (category.calendarType) {
            typeChecked = true;
            if (category.calendarType == 6 || category.calendarType == 7 || category.calendarType == 1) {
              showsScreen = true; //nights & dresscode & alldayactivities(in case)
            }
          }
        }
      });
    }
  }

  if (typeChecked) {
    if (showsScreen) {
      return "/showseventcalendar/" + itemId + "/" + getServiceType(serviceTypeId);
    } else {
      return "/eventcalendar/" + itemId + "/" + getServiceType(serviceTypeId);
    }
  } else {
    return "/redirectioneventcalendar/" + itemId + "/" + getServiceType(serviceTypeId);
  }
};

const getServiceUrl = function (
  itemId,
  serviceTypeId,
  userData,
  isLobby,
  isDesktop,
  isTV,
  showProductDetail,
  productId
) {
  let url = "";
  switch (serviceTypeId) {
    case 7:
    case 17:
    case 25:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
    case 18:
    case 36:
    case 38:
    case 39:
    case 40:
    case 41:
    case 54:
    case 56:
    case 57:
    case 58:
    case 59:
    case 60:
      url = "/webview/3/" + getServiceType(serviceTypeId) + "/" + itemId + "/" + 0;
      if (showProductDetail) {
        url += "/product/" + productId;
      }
      break;
    case 48:
    case 49:
    case 50:
      url = "/webview/3/" + getServiceType(serviceTypeId) + "/" + itemId + "/" + 0;
      if (showProductDetail) {
        url += "/product/" + productId;
      }
      break;
    case 14:
    case 15:
    case 37: //eventscalendar
      url = "/activity/" + getServiceType(serviceTypeId) + "/" + itemId;
      break;
    case 16:
      url = "/my-congress/" + getServiceType(serviceTypeId);
      break;
    case 26:
    case 30:
    case 51:
    case 31:
      url = "/requests/3/" + getServiceType(serviceTypeId);
      break;
    case 55:
      url = "/webview/3/" + getServiceType(serviceTypeId) + "/" + itemId + "/0";
      break;
    case 42:
    case 46: //grouping_map
      url = "/grouping/" + itemId;
      break;
  }

  url = checkLobbyOrDesktopForURL(url, isLobby, isDesktop, isTV);

  return url;
};

const getServiceType = function (serviceTypeId) {
  switch (serviceTypeId) {
    case 7:
      return "room";
    case 8:
      return "restaurant";
    case 9:
      return "spa";
    case 10:
      return "sport";
    case 11:
      return "tour";
    case 12:
      return "poi";
    case 13:
      return "premiumservice";
    case 14:
      return "activity";
    case 15:
      return "show";
    case 16:
      return "congress";
    case 17:
      return "weddings";
    case 18:
      return "roomservice";
    case 25:
      return "custom";
    case 26:
      return "issues";
    case 30:
      return "amenities";
    case 31:
      return "housekeeping";
    case 37:
      return "eventcalendar";
    case 36:
      return "roomservicev2";
    case 38:
      return "otherfacilities";
    case 39:
      return "pool";
    case 40:
      return "attraction";
    case 41:
      return "shop";
    case 42:
      return "grouping";
    case 46:
      return "grouping_map"; //map v2
    case 48:
      return "covid19";
    case 49:
      return "welcome_message";
    case 50:
      return "laundry";
    case 51:
      return "checkout";
    case 52:
      return "grouping_search";
    case 54:
      return "miniclub";
    case 55:
      return "other_requests";
    case 56:
      return "gym";
    case 57:
      return "directory";
    case 58:
      return "cityguide";
    case 59:
      return "balinese_bed";
    case 60:
      return "environment";
    default:
      return "";
  }
};

const serviceTypes = {
  //cmsModule: serviceTypeLiteral
  4: "restaurant",
  5: "spa",
  6: "sport",
  7: "tour",
  8: "poi",
  9: "premiumservice",
  10: "activity",
  11: "show",
  18: "roomservice",
  30: "wedding",
  43: "custom",
  49: "issues",
  50: "laundry",
  73: "amenities",
  74: "housekeeping",
  77: "externalservicepoi",
  78: "externalservicerestaurant",
  79: "externalservicetour",
  83: "roomservicev2",
  84: "eventcalendar",
  86: "otherfacilities",
  87: "pool",
  88: "attraction",
  90: "shop",
  91: "grouping",
  96: "grouping_map",
  97: "request_chat",
  98: "covid19",
  99: "welcome_message",
  100: "laundry",
  101: "checkout",
  102: "grouping_search",
  105: "miniclub",
  106: "other_requests",
  107: "gym",
  108: "directory",
  110: "cityguide",
  111: "balinese_bed",
  112: "environment",
  115: "multi-establishments"
};

const moduleForServiceType = {
  restaurant: 4,
  spa: 5,
  sport: 6,
  tour: 7,
  poi: 8,
  premiumservice: 9,
  activity: 10,
  show: 11,
  roomservice: 18,
  issues: 49,
  amenities: 73,
  housekeeping: 74,
  externalservicepoi: 77,
  externalservicerestaurant: 78,
  externalservicetour: 79,
  roomservicev2: 83,
  eventcalendar: 84,
  event_calendar: 84,
  otherfacilities: 86,
  pool: 87,
  attraction: 88,
  shop: 90,
  grouping: 91,
  grouping_map: 96,
  covid19: 98,
  welcome_message: 99,
  laundry: 100,
  checkout: 101,
  grouping_search: 102,
  miniclub: 105,
  other_requests: 106,
  gym: 107,
  directory: 108,
  cityguide: 110,
  balinese_bed: 111,
  environment: 112
};

const getServiceTypeId = function (serviceType) {
  switch (serviceType) {
    case "room":
      return 7;
    case "restaurant":
      return 8;
    case "spa":
      return 9;
    case "sport":
      return 10;
    case "tour":
      return 11;
    case "poi":
      return 12;
    case "premiumservice":
      return 13;
    case "activity":
      return 14;
    case "show":
      return 15;
    case "congress":
      return 16;
    case "weddings":
      return 17;
    case "roomservice":
      return 18;
    case "custom":
      return 25;
    case "issues":
      return 26;
    case "amenities":
      return 30;
    case "housekeeping":
      return 31;
    case "roomservicev2":
      return 36;
    case "eventcalendar":
    case "event_calendar":
      return 37;
    case "otherfacilities":
      return 38;
    case "pool":
      return 39;
    case "attraction":
      return 40;
    case "shop":
      return 41;
    case "grouping":
      return 42;
    case "grouping_map":
      return 46;
    case "covid19":
      return 48;
    case "welcome_message":
      return 49;
    case "laundry":
      return 50;
    case "checkout":
      return 51;
    case "grouping_search":
      return 52;
    case "miniclub":
      return 54;
    case "other_requests":
      return 55;
    case "gym":
      return 56;
    case "directory":
      return 57;
    case "cityguide":
      return 58;
    case "balinese_bed":
      return 59;
    case "environment":
      return 60;
    default:
      return 0;
  }
};

const showRequestFunnel = function (
  serviceId,
  productId,
  productTypeId,
  cmsModule,
  imageUrl,
  productName,
  moduleName,
  establishment,
  userData,
  backgroundImageUrl,
  isGA,
  productNameEnglish,
  returnParams
) {
  const bookingFields = [];

  const commentsField = {
    fieldName: "comment",
    fieldText: "COMMENTS",
    optional: true,
    type: "text",
    values: ""
  };
  bookingFields.push(commentsField);

  const actionField = {
    fieldText: "BOOK",
    requiredFields: ["service"],
    type: "action",
    url: "/v1/leads/inapp/create"
  };
  bookingFields.push(actionField);

  const serviceField = {
    type: "hidden",
    fieldValue: serviceId,
    fieldName: "service"
  };
  bookingFields.push(serviceField);

  const productField = {
    type: "hidden",
    fieldValue: productId,
    fieldName: "productId"
  };
  bookingFields.push(productField);

  const productTypeField = {
    type: "hidden",
    fieldValue: productTypeId,
    fieldName: "productType"
  };
  bookingFields.push(productTypeField);

  const path = isGA ? "/booking-form-ga/" : "/booking-form/";
  const params = {
    preFilledBookingFields: bookingFields,
    serviceId: serviceId,
    preFilledProductId: productId,
    preFilledBookingModule: cmsModule,
    preFilledImageUrl: imageUrl,
    preFilledBackgroundImageUrl: backgroundImageUrl,
    preFilledItemName: productName,
    preFilledItemNameEnglish: productNameEnglish,
    preFilledModuleName: moduleName,
    establishment: establishment,
    psId: userData.id,
    userkey: userData.userKey,
    apikey: config.apiKey,
    devicekey: userData.deviceKey
  };

  if (returnParams) {
    return params;
  }

  if (isGA) {
    return this.$router.push({
      path: path,
      params: { serviceId: this.service.id },
      state: { params: cloneDeep(params) },
      name: "BookingFormGA"
    });

    // return router.push({ path: path, params: params, name: "BookingFormGA" });
  }

  return router.push({
    path: path,
    params: { serviceId: params.serviceId },
    name: "BookingForm",
    state: { params: cloneDeep(params) }
  });
};

const urlB64ToUint8Array = function (base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const getNameTranslatable = function (itemName) {
  if (typeof itemName === "string") {
    return itemName;
  } else if (typeof itemName === "object" && !Array.isArray(itemName)) {
    return translate(itemName, store.state.user);
  } else {
    return "";
  }
};

const logScreen = function (pageName, category, serviceType, serviceId, realURL = window.document.location.pathname) {
  if (store.state.cmsMode && store.state.cmsMode === true) {
    return;
  }
  analytics.logScreen(pageName, category, serviceType, serviceId, realURL);
};

const logECommerceScreen = function (
  pageName,
  category,
  subcategory,
  eventAct,
  eventLbl,
  params,
  realURL = window.document.location.pathname
) {
  if (store.state.cmsMode && store.state.cmsMode === true) {
    return;
  }
  logGeneral();
  const data = {
    "real-URL": realURL,
    pageName: pageName.replace(/ - /g, "-").replace(/ /g, "-"),
    category: category,
    subcategory: subcategory,
    userStatus: analytics.getAnalyticsUserStatus(),
    event: "eventoEC",
    eventCat: "ecommerce",
    eventAct: eventAct,
    eventLbl: eventLbl,
    ecommerce: eCommerceForEventAct(eventAct, {
      eventLbl: eventLbl,
      category: category,
      subcategory: subcategory,
      params: params
    })
  };
};

const eCommerceForEventAct = function (eventAct, params) {
  let ecommerceData = {};
  if (eventAct === "productDetail") {
    ecommerceData = eCommerceDataForProductDetail(params);
  } else if (eventAct === "addToCart") {
    ecommerceData = eCommerceDataForAddToCart(params);
  } else if (eventAct === "checkout") {
    ecommerceData = eCommerceDataForCheckout(params);
  } else if (eventAct === "purchase") {
    ecommerceData = eCommerceDataForPurchase(params);
  } else if (eventAct === "listProductsViews") {
    ecommerceData = eCommerceDataForListProductsView(params);
  } else if (eventAct === "listProductClick") {
    ecommerceData = eCommerceDataForListProductClick(params);
  } else if (eventAct === "removeFromCart") {
    ecommerceData = eCommerceDataForRemoveFromCart(params);
  }

  return ecommerceData;
};

const eCommerceDataForProductDetail = function (params) {
  const eCommerceData = {
    detail: {
      products: eCommerceProductsData([params.params], {
        quantity: 0,
        category: params.category,
        subcategory: params.subcategory,
        eventLbl: params.eventLbl
      })
    }
  };

  return eCommerceData;
};

const eCommerceDataForAddToCart = function (params) {
  const eCommerceData = {
    currencyCode: "EUR",
    add: {
      products: eCommerceProductsData([params.params], {
        quantity: 1,
        category: params.category,
        subcategory: params.subcategory,
        eventLbl: params.eventLbl
      })
    }
  };

  return eCommerceData;
};

const eCommerceDataForRemoveFromCart = function (params) {
  const eCommerceData = {
    currencyCode: "EUR",
    remove: {
      products: eCommerceProductsData([params.params], {
        quantity: 1,
        category: params.category,
        subcategory: params.subcategory,
        eventLbl: params.eventLbl
      })
    }
  };

  return eCommerceData;
};

const eCommerceDataForCheckout = function (params) {
  let serviceIdentifier = null;
  if (params.params.serviceIdentifier) {
    serviceIdentifier = params.params.serviceIdentifier;
  }
  const eCommerceData = {
    checkout: {
      actionField: { step: params.eventLbl },
      products: eCommerceProductsData(params.params.products, {
        quantity: params.params.quantity,
        category: params.category,
        subcategory: params.subcategory,
        eventLbl: params.eventLbl,
        serviceIdentifier: serviceIdentifier
      })
    }
  };

  return eCommerceData;
};

const eCommerceDataForListProductClick = function (params) {
  let serviceIdentifier = null;
  if (params.params.serviceIdentifier) {
    serviceIdentifier = params.params.serviceIdentifier;
  }
  const eCommerceData = {
    click: {
      actionField: { list: params.params.listName },
      products: eCommerceProductsData([params.params.product], {
        position: params.params.position,
        category: params.category,
        subcategory: params.subcategory,
        eventLbl: params.eventLbl,
        serviceIdentifier: serviceIdentifier
      })
    }
  };

  return eCommerceData;
};

const eCommerceDataForListProductsView = function (params) {
  const eCommerceData = {
    currencyCode: "EUR",
    impressions: eCommerceProductsData(params.params.products, {
      list: params.params.listName,
      category: params.category,
      subcategory: params.subcategory,
      eventLbl: params.eventLbl
    })
  };

  return eCommerceData;
};

const eCommerceDataForPurchase = function (params) {
  let serviceIdentifier = null;
  if (params.params.serviceIdentifier) {
    serviceIdentifier = params.params.serviceIdentifier;
  }
  const eCommerceData = {
    purchase: {
      actionField: {
        id: params.params.products[0].id.toString(),
        affiliation: "pwa",
        revenue: params.params.products[0].bookingFinalPrice
          ? params.params.products[0].bookingFinalPrice.toString()
          : "0",
        tax: "",
        shipping: "",
        coupon: ""
      },
      products: eCommerceProductsData(params.params.products, {
        category: params.category,
        subcategory: params.subcategory,
        eventLbl: params.eventLbl,
        serviceIdentifier: serviceIdentifier
      })
    }
  };

  return eCommerceData;
};

const eCommerceProductsData = function (products, commonData) {
  var eCommerceProducts = [];
  if (!products) {
    return [];
  }
  products.forEach(function (product, index) {
    eCommerceProducts.push(eCommerceProductData(product, commonData, index + 1));
  });

  return eCommerceProducts;
};

const eCommerceProductData = function (product, commonData, index) {
  const establishment = store.state.establishment;
  let categoryIdentifier = "";
  if (commonData.category == "request") {
    categoryIdentifier = commonData.category + "/" + commonData.subcategory;
  } else if (commonData.serviceIdentifier) {
    categoryIdentifier = commonData.subcategory + "/" + commonData.serviceIdentifier;
  } else {
    categoryIdentifier = commonData.subcategory + "/" + commonData.eventLbl;
  }
  let establishmentName = establishment.name;
  if (establishmentName) {
    establishmentName = establishment.name.replace(/ /g, "-");
  }

  const data = {
    id: product.id.toString(),
    category:
      establishment.commercialEntity +
      "/" +
      establishment.commercialEntity +
      "/" +
      establishmentName +
      "-" +
      establishment.id +
      "/" +
      categoryIdentifier,
    variant: "",
    brand: ""
  };

  if (product.translatableName) {
    data["name"] = translate(product.translatableName, { locale: "en" });
  } else if (product.serviceName) {
    data["name"] = product.serviceName;
  }
  if (product.price) {
    data["price"] = product.price.toString();
  } else if (
    product.productTypes &&
    product.productTypes[Object.keys(product.productTypes)[0]] &&
    product.productTypes[Object.keys(product.productTypes)[0]].price
  ) {
    data["price"] = product.productTypes[Object.keys(product.productTypes)[0]].price.toString();
  } else if (product.bookingFinalPrice) {
    data["price"] = product.bookingFinalPrice.toString();
  }
  if (commonData.quantity > 0) {
    data["quantity"] = commonData.quantity;
  }
  if (commonData.list) {
    data["list"] = commonData.list;
  }
  if (commonData.position) {
    data["position"] = commonData.position;
  } else if (index) {
    data["position"] = index;
  }

  return data;
};

const logVirtualPage = function (pageName, category, subcategory) {
  if (store.state.cmsMode && store.state.cmsMode === true) {
    return;
  }
  analytics.logVirtualPage(pageName, category, subcategory);
};

const logBackButton = function (pageName, category, subcategory) {
  if (store.state.cmsMode && store.state.cmsMode === true) {
    return;
  }
};

const logExternalUrl = function (externalUrl, pageName, category, subcategory) {
  if (store.state.cmsMode && store.state.cmsMode === true) {
    return;
  }
  analytics.logExternalUrl(externalUrl, pageName, category, subcategory);
};

const logGTMEvent = function (
  pageName,
  category,
  subcategory,
  eventCat,
  eventAction,
  eventLbl,
  event = "eventoGA",
  extraData
) {
  if (store.state.cmsMode && store.state.cmsMode === true) {
    return;
  }
  logGeneral();
  let data = {
    "real-URL": window.document.location.pathname,
    pageName: pageName.replace(/ - /g, "-").replace(/ /g, "-"),
    event: event,
    eventCat: eventCat,
    eventAct: eventAction,
    eventLbl: eventLbl.replace(/ - /g, "-").replace(/ /g, "-"),
    eventTimeout: 2000,
    category: category,
    subcategory: subcategory,
    userStatus: analytics.getAnalyticsUserStatus()
  };
  if (extraData) {
    data = Object.assign(data, extraData);
  }
};

const logGTMFrontEvent = function (
  pageName,
  category,
  subcategory,
  eventCat,
  eventAction,
  eventLbl,
  event = "eventoGA",
  extraData
) {
  if (store.state.cmsMode && store.state.cmsMode === true) {
    return;
  }
  logGeneral();

  let data = {
    "real-URL": window.document.location.pathname,
    pageName: pageName.replace(/ - /g, "-").replace(/ /g, "-"),
    event: event,
    eventCat: eventCat,
    eventAct: eventAction,
    eventLbl: eventLbl,
    eventTimeout: 2000,
    category: category,
    subcategory: subcategory,
    userStatus: analytics.getAnalyticsUserStatus()
  };
  if (extraData) {
    data = Object.assign(data, extraData);
  }

  const environment = import.meta.env.NODE_ENV;
  const testingLogs = false;
  if (!testingLogs && environment !== "production") {
    return;
  }
  analytics.logFrontEvent(pageName, eventCat, eventAction, eventLbl, extraData);
};

const categoryGTMforServiceType = function (serviceType) {
  let category = "facilities";
  if (
    serviceType === "premiumservice" ||
    serviceType === "spa" ||
    serviceType === "roomservice" ||
    serviceType === "custom" ||
    serviceType === "otherfacilities" ||
    serviceType === "pool" ||
    serviceType === "shop" ||
    serviceType === "attraction" ||
    serviceType === "grouping"
  ) {
    category = "services";
  } else if (serviceType === "tour" || serviceType === "poi" || serviceType === "cityguide") {
    category = "destination";
  } else if (
    serviceType === "amenities" ||
    serviceType === "housekeeping" ||
    serviceType === "issues" ||
    serviceType === "checkout" ||
    serviceType === "otherrequests"
  ) {
    category = "request";
  } else if (serviceType === "activity" || serviceType === "shows" || serviceType === "eventcalendar") {
    category = "entertainment";
  }
  return category;
};

const getUserStatus = function (userData) {
  if (!userData) {
    return "VISITOR";
  }

  const checkIn = userData.checkIn;
  const checkOut = userData.checkOut;
  const room = userData.room;
  const userType = userData.userType;

  if (userType === "POSTCLIENT") {
    return "VISITOR";
  }

  if (checkIn && checkOut) {
    const now = moment();
    const checkInDate = moment(checkIn);
    const checkOutDate = moment(checkOut).add(1, "days");

    if ((now.isBefore(checkOutDate) && now.isSameOrAfter(checkInDate)) || (now.isBefore(checkInDate) && room)) {
      return "CLIENT";
    } else if (now.isBefore(checkInDate) && !room) {
      return "PRE_CLIENT";
    }
  }

  return "VISITOR";
};
const dayFirstLetter = function (dayNumber, momentServer) {
  if (momentServer) {
    const localeM = moment(momentServer);
    localeM.locale(window.navigator.language);
    let firstChar = localeM.add(dayNumber, "days").format("dd");
    if (firstChar.toLowerCase() === "mi" && localeM.locale() === "es") {
      firstChar = "X";
    }
    if (localeM.locale() === "ca") {
      return firstChar.substring(0, 1).toUpperCase() + firstChar.substring(1, 2);
    } else {
      return firstChar.substring(0, 1).toUpperCase();
    }
  }
};

const isLogged = function (user) {
  if (!user || !user.establishments || !user.establishments[user.establishmentId]) {
    return false;
  }
  const userStatus = getUserStatus(user.establishments[user.establishmentId]);
  if (userStatus !== "CLIENT") {
    if (
      store.state.establishment?.id &&
      store.state.tokenUserArray &&
      store.state.tokenUserArray[store.state.establishment.id] !== ""
    ) {
      store.commit(types.SET_TOKEN_USER, "");
    }
    if (
      store.state.establishment?.id &&
      store.state.digitalKeyFieldArray &&
      store.state.digitalKeyFieldArray[store.state.establishment.id]
    ) {
      store.commit(types.SET_DIGITAL_KEY_FIELD, []);
    }
  }
  return (
    user.establishments &&
    user.establishments[user.establishmentId] &&
    (userStatus === "CLIENT" || userStatus === "PRE_CLIENT")
  );
};

const logGeneral = function () {
  logUserID(store.state.user.uid);
  LogEstablishment(store.state.establishment);
  logChain(store.state.establishment);
};

const logProductType = function (productType) {
  if (store.state.cmsMode && store.state.cmsMode === true) {
    return;
  }
};
const LogEstablishment = function (establishment) {
  if (store.state.cmsMode && store.state.cmsMode === true) {
    return;
  }
  let idToLog = "";
  try {
    if (establishment.hasOwnProperty("name") && establishment.hasOwnProperty("id")) {
      idToLog = establishment.name.replace(/ /g, "-") + "-" + establishment.id;
    }
  } catch (error) {
    console.error(error);
  }
};

const logChain = function (establishment) {
  if (store.state.cmsMode && store.state.cmsMode === true) {
    return;
  }
};

const logUserID = function (userID) {
  if (store.state.cmsMode && store.state.cmsMode === true) {
    return;
  }
};

const capitalized = function (string) {
  return string.substring(0, 1).toUpperCase() + string.substring(1, string.length);
};

const getImageFromService = function (serviceId) {
  let imageUrl = undefined;
  const servicesValues = Object.values(store.state.services);
  if (servicesValues && servicesValues.length > 0) {
    servicesValues.forEach(function (services) {
      const service = services.filter(function (s) {
        return s.id === serviceId;
      });
      if (service && service.length > 0) {
        imageUrl = service[0].photographs ? getImageUrl(service[0].photographs[0]) : undefined;
      }
    });
  }
  return imageUrl;
};

const findService = function (serviceId, serviceType, services) {
  if (!serviceId) {
    return undefined;
  }
  if (serviceId && serviceType) {
    return getServiceWithServiceType(serviceId, serviceType, services);
  }

  const serviceTypes = Object.keys(services);
  if (!serviceTypes || serviceTypes.length === 0) {
    return;
  }

  const storeServices = services;
  let serviceData = undefined;
  serviceTypes.forEach(function (serviceType) {
    const svs = storeServices[serviceType];
    if (svs && !serviceData) {
      const thisService = svs.filter(function (service) {
        return service.id === serviceId;
      });
      if (thisService && thisService.length > 0) {
        serviceData = thisService[0];
      }
    }
  });
  return serviceData;
};

const getServiceWithServiceType = function (serviceId, serviceType, services) {
  const svs = services[serviceType];
  if (!svs) {
    return;
  }
  const thisService = svs.filter(function (service) {
    return service.id === serviceId;
  });
  if (thisService && thisService.length > 0) {
    return thisService[0];
  }
  return undefined;
};

const deleteYearFromMoment = function (locale, dateString) {
  if (locale === "es") {
    // moment().format('ll');   // 23 de mar. de 2018
    dateString = dateString.substring(0, dateString.length - 8);
  } else if (locale === "en") {
    // We delete de year and then we delete the ',' character
    // USA: March 23, 2018
    // UK: 23 Mar 2018
    dateString = dateString.substring(0, dateString.length - 6);
    dateString = dateString.replace(",", "");
  }
  return dateString;
};

const getModuleName = function (cmsModule) {
  cmsModule = parseInt(cmsModule);
  switch (cmsModule) {
    case 4:
      return "RESTAURANTS";
    case 5:
      return "SPA";
    case 6:
      return "SPORT";
    case 7:
      return "TOUR";
    case 8:
      return "POI";
    case 9:
      return "PREMIUM_SERVICES";
    case 13:
      return "CONGRESSES";
    case 18:
      return "ROOM_SERVICE";
    case 30:
      return "WEDDINGS";
    case 49:
      return "ISSUES";
    case 54:
      return "MY_TICKETS";
    case 73:
      return "AMENITIES";
    case 74:
      return "HOUSEKEEPING";
    case 77:
    case 78:
    case 79:
      return "POI";
    case 83:
      return "ROOM_SERVICE";
    case 84:
      return "EVENT_CALENDAR";
    case 86:
      return "OTHER_FACILITIES";
    case 87:
      return "POOL";
    case 88:
      return "ATTRACTION";
    case 90:
      return "SHOP";
    case 91:
      return "GROUPING";
    case 92:
      return "WAITING_TIME";
    case 93:
      return "HOW_TO_GET";
    case 96:
      return "GROUPING_MAP";
    case 100:
      return "LAUNDRY";
    case 101:
      return "CHECKOUT";
    case 102:
      return "GROUPING_SEARCH";
    case 108:
      return "DIRECTORY";
    case 110:
      return "CITY_GUIDE";
  }
  return "";
};

const getEventById = function (id) {
  if (store.state.congressEvents) {
    if (store.state.congressEvents[id]) {
      return store.state.congressEvents[id];
    }
  }
};

const getUpdateEvent = function (event) {
  const oldEvent = getEventById(event.id);
  if (!event.hasOwnProperty("speakers")) {
    if (oldEvent.hasOwnProperty("speakers")) {
      event.speakers = oldEvent.speakers;
    }
  }

  if (!event.hasOwnProperty("activities")) {
    if (oldEvent.hasOwnProperty("activities")) {
      event.activities = oldEvent.activities;
    }
  }

  if (!event.hasOwnProperty("attendants")) {
    if (oldEvent.hasOwnProperty("attendants")) {
      event.attendants = oldEvent.attendants;
    }
  }

  if (!event.hasOwnProperty("companies")) {
    if (oldEvent.hasOwnProperty("companies")) {
      event.companies = oldEvent.companies;
    }
  }

  if (event.hasOwnProperty("deletedCompanies")) {
    if (event.hasOwnProperty("companies")) {
      const companies = [];
      for (let t = 0; t < event.companies.length; t++) {
        let del = false;
        for (let h = 0; h < event.deletedCompanies.length; h++) {
          if (event.deletedCompanies[h] === companies.id) {
            del = true;
            break;
          }
        }
        if (del === false) {
          companies.push(event.companies[t]);
        }
      }
      event.companies = companies;
    }
  }

  if (event.hasOwnProperty("deletedAttendants")) {
    if (event.hasOwnProperty("attendants")) {
      const attendants = [];
      for (let t = 0; t < event.attendants.length; t++) {
        let del = false;
        for (let h = 0; h < event.deletedAttendants.length; h++) {
          if (event.deletedAttendants[h] === attendants.id) {
            del = true;
            break;
          }
        }
        if (del === false) {
          attendants.push(event.attendants[t]);
        }
      }
      event.attendants = attendants;
    }
  }

  if (event.hasOwnProperty("deletedActivities")) {
    if (event.hasOwnProperty("activities")) {
      const activities = [];
      for (let t = 0; t < event.activities.length; t++) {
        let del = false;
        for (let h = 0; h < event.deletedActivities.length; h++) {
          if (event.deletedActivities[h] === activities.id) {
            del = true;
            break;
          }
        }
        if (del === false) {
          activities.push(event.activities[t]);
        }
      }
      event.activities = activities;
    }
  }

  if (event.hasOwnProperty("deletedSpeakers")) {
    if (event.hasOwnProperty("speakers")) {
      const speakers = [];
      for (let t = 0; t < event.speakers.length; t++) {
        let del = false;
        for (let h = 0; h < event.deletedSpeakers.length; h++) {
          if (event.deletedSpeakers[h] === speakers.id) {
            del = true;
            break;
          }
        }
        if (del === false) {
          speakers.push(event.speakers[t]);
        }
      }
      event.speakers = speakers;
    }
  }

  return event;
};

const checkStr = function (str) {
  return typeof str !== "undefined" && str !== null && str !== "";
};

const checkStrTrim = function (str) {
  if (typeof str !== "undefined" && str !== null && str !== "") {
    return str.trim() !== "";
  }

  return false;
};

const enterToEvent = function (id) {
  try {
    // this.$router.push({

    let nameRoute = "CongressSumary";

    if (store.state.isTV) {
      nameRoute = "TVLandscapeCongressDetail";
    } else if (store.state.isLobby) {
      nameRoute = "LobbyLandscapeCongressDetail";
    } else if (store.state.isDesktop) {
      nameRoute = "LandscapeCongressDetail";
    }

    router.push({
      params: {
        idCongress: id
      },
      name: nameRoute,
      query: { id: getHashId() }
    });
  } catch (e) {
    console.error("error: " + e);
  }
};

const deviceDetector = (function () {
  // deviceDetector.device contains the device you're browsing on. desktop, tablet or phone.
  // deviceDetector.isMobile is true if the device is a phone or tablet. False otherwise.
  // deviceDetector.userAgent contains the user agent string the check is performed against.
  var ua = navigator.userAgent.toLowerCase();
  var detect = function (s) {
    if (s === undefined) {
      s = ua;
    } else {
      ua = s.toLowerCase();
    }
    if (
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        ua
      )
    )
      return "tablet";
    else if (
      /(mobi|ipod|ios|melia-b2c|phone|blackberry|opera mini|fennec|minimo|symbian|psp|nintendo ds|archos|skyfire|puffin|blazer|bolt|gobrowser|iris|maemo|semc|teashark|uzard)/.test(
        ua
      )
    )
      return "phone";
    else return "desktop";
  };
  return {
    device: detect(),
    detect: detect,
    isMobile: detect() != "desktop" ? true : false,
    userAgent: ua
  };
})();

const checkLobbyOrDesktopForURL = function (url, isLobby, isDesktop, isTV) {
  if (store.state.isDesktop || store.state.isLobby) {
    if (url.substring(0, 1) !== "/") {
      url = "/" + url;
    }

    if (store.state.isDesktop) {
      if (store.state.isTV) {
        url = "/tv" + url;
      } else {
        url = "/desktop" + url;
      }
    } else if (store.state.isLobby) {
      url = "/lobby" + url;
    }
  }

  return url;
};

const getserviceTimetableAndExceptional = function (timetable, timetableExceptional) {
  const date = moment();
  const dateWeek = moment().add(7, "days");
  if (timetableExceptional != undefined) {
    Object.keys(timetableExceptional).forEach(key => {
      const value = timetableExceptional[key];
      const dateException = moment(key);
      if (dateException.isSameOrAfter(date, "day") && dateException.isBefore(dateWeek, "day")) {
        const weekDayName = moment(key).locale("en").format("dddd").toLowerCase();
        const data = value;
        Object.keys(timetable).forEach(key => {
          if (key == weekDayName) {
            if (data && data.closed) {
              delete timetable[key];
            } else {
              timetable[key] = data;
            }
          }
        });
      }
    });
  }
  return timetable;
};

const sendDataStatusAlert = function (data) {
  store
    .dispatch("sendStatusAlert", data)
    .then(responseData => {})
    .catch(err => {});
};

const datesFilter = function (response, start, end) {
  const filtered = [];

  const startDate = moment(start).format("YYYY-MM-DD");
  const endDate = moment(end).format("YYYY-MM-DD");

  if (response) {
    response.forEach(item => {
      if (item.eventDay) {
        const eventday = item.eventDay;
        const eventdayMoment = moment(eventday).format("YYYY-MM-DD");
        if (eventdayMoment <= endDate && eventdayMoment >= startDate) {
          filtered.push(item);
        }
      }
    });
  }

  return filtered;
};

const setCurrencyToPrice = function (price, currency = "", withSpace, currencyData = {}, dontShowISOCode) {
  if (!price) {
    return "";
  }

  if (price === "-") {
    return "-";
  }
  let locale = window.navigator.language.substring(0, 2);
  if (!locale) {
    locale = "en";
  }
  if (price === undefined) {
    return "";
  }
  const isEgyptian = currencyData.symbol === "£" && currencyData.isoCode === "EGP";
  const currencyValidation =
    (currencyData.symbol === "$" || isEgyptian) && !dontShowISOCode && !currency.includes(currencyData.isoCode);

  const space = withSpace ? " " : "";
  if (locale === "en") {
    if (currencyValidation) {
      return currency + space + price + " " + currencyData.isoCode;
    } else {
      return currency + space + price;
    }
  } else {
    if (currencyValidation) {
      return price + space + currency + " " + currencyData.isoCode;
    } else {
      return price + space + currency;
    }
  }
};

const checkEventPast = function (event, locale) {
  if (event.endDate) {
    //we add one day to avoid problems with hours
    const endMoment = moment(moment(event.endDate).add(1, "days").format("YYYY-MM-DD")).locale(locale);
    const momentNow = moment().locale(locale);
    if (endMoment >= momentNow) {
      return false;
    } else {
      return true;
    }
  }
  return false;
};

const hasEmbedApiKey = function () {
  const embeddedApiKeys = [RIU_KEY, MELIA_KEY];
  return embeddedApiKeys.indexOf(store.state.apiKey) !== -1;
};

const isEmbedPWA = function () {
  return (
    window.location.search.indexOf("utm_medium=embed") !== -1 ||
    window.location.search.indexOf("embed=1") !== -1 ||
    store.state.embeddedType === "native_frontpage" ||
    store.state.embeddedType === "full" ||
    hasEmbedApiKey()
  );
};

const isRiuApp = function (apikey) {
  return apikey === RIU_KEY;
};

const couldBeNativeEmbeddedPWA = appId => {
  const apiKeyList = [PWA_APP_ID, MELIA_APP_ID, RIU_APP_ID, HYATTPWA_APP_ID];
  return !apiKeyList.includes(appId);
};

const isEmbedReact = appId => {
  return isFullEmbedPWA() && couldBeNativeEmbeddedPWA(appId);
};

const isEmbeddedPWAByAppId = appId => {
  return appId !== PWA_APP_ID;
};

const isUrlTokenAppId = token => {
  if (!token) return false;

  const decodedToken = decode(token);
  const data = decodedToken?.data;
  if (!data) {
    return false;
  }
  return data.appId !== PWA_APP_ID;
};

const isFullEmbedPWA = function () {
  return store.state.embeddedType === "full" || isRiuApp(store.state.apiKey);
};

const isMonoHotelApp = function () {
  return store.state.isMonoEstablishmentApp === true;
};

const getCurrencyDataSymbol = function (data) {
  if (data && data.symbol === "$") {
    return data.symbol + " " + data.isoCode + "";
  } else if (data && data.symbol === "£" && data.isoCode !== "GBP") {
    return data.symbol + " " + data.isoCode + "";
  } else if (data && data.symbol) {
    return data.symbol;
  } else {
    return "";
  }
};

//to update in background
const checkHours = function (lastTs, intervalSeconds) {
  if (!lastTs) {
    return true;
  }
  const interval = intervalSeconds;
  const currentTs = Math.round(Date.now() / 1000);
  const lastTsCheck = lastTs + interval; //2 hours in seconds
  if (lastTsCheck < currentTs) {
    return true;
  }
  return false;
};

const getStatuswelcomeShowVisible = function () {
  const { establishmentHash } = store.state;
  if (store.state.welcomeShowVisible[establishmentHash]) {
    return true;
  } else {
    return false;
  }
};

const getmapResponse = function (obj) {
  const fieldsArray = [];
  for (var fieldObj in obj) {
    let required = false;
    let hidden = false;
    let keyName = "";
    let type = "";

    if (fieldObj && obj[fieldObj].required) {
      required = obj[fieldObj].required;
    }
    if (fieldObj && obj[fieldObj].hidden) {
      hidden = obj[fieldObj].hidden;
    }
    if (fieldObj && obj[fieldObj].key) {
      keyName = obj[fieldObj].key;
    }
    if (fieldObj && obj[fieldObj].type) {
      type = obj[fieldObj].type;
    }
    const field = {
      key: fieldObj,
      required: required,
      hidden: hidden,
      keyName: keyName,
      type: type
    };
    fieldsArray.push(field);
  }
  return fieldsArray;
};

const convertHexToRGBA = function (hex, opacity) {
  hex = hex.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  const result = "rgba(" + r + "," + g + "," + b + "," + opacity + ")";
  return result;
};

// const signJWT = function(payload, privateToken) {
//   var sPayload = JSON.stringify(payload);
//   const token = sign(sPayload, privateToken);
//   return token;
// };

const showCatalogue = function (serviceType) {
  const serviceTypeArray = [
    "restaurant",
    "covid19",
    "otherfacilities",
    "pool",
    "sport",
    "premiumservice",
    "welcome_message",
    "attraction",
    "tour",
    "poi",
    "cityguide",
    "gym",
    "balinese_bed"
  ];
  if (serviceTypeArray.includes(serviceType)) {
    return true;
  } else {
    return false;
  }
};

const hasPrice = function (product) {
  if (product) {
    const value = product.priceOld
      ? product.priceOld.toFixed(2)
      : product.price
        ? product.price.toFixed(2)
        : product.price === 0
          ? "0"
          : "-";
    if (value === "-") {
      return false;
    }
  } else {
    return false;
  }
  return true;
};

const isSpaceOrderBookingUserData = booking => {
  if (
    booking &&
    booking.customData &&
    booking.customData.bookingSpace &&
    booking.customData.bookingSpace.orderId &&
    booking.bookingSpace.hasOwnProperty("orderStatus")
  ) {
    return {
      bookingId: booking.customData.bookingSpace.id,
      orderStatus: booking.customData.bookingSpace.orderStatus,
      orderId: booking.customData.bookingSpace.orderId
    };
  }
  if (
    booking &&
    booking.bookingSpace &&
    booking.bookingSpace.orderId &&
    booking.bookingSpace.hasOwnProperty("orderStatus") &&
    !checkSpacesBookingWithTurnByModule(booking)
  ) {
    return {
      bookingId: booking.bookingSpace.id,
      orderStatus: booking.bookingSpace.orderStatus,
      orderId: booking.bookingSpace.orderId
    };
  }
  //notifications check
  if (
    booking &&
    booking.hasOwnProperty("spaceOrderStatus") &&
    booking.spaceOrderStatus != undefined &&
    !checkSpacesBookingWithTurnByModule(booking)
  ) {
    return {
      orderStatus: booking.spaceOrderStatus
    };
  }

  return false;
};
const checkSpacesBookingWithTurnByModule = booking => {
  if (booking?.bookingEnabled == 7 || booking?.bookingSpace?.bookingEnabled == 7) {
    return true;
  }

  return false;
};
const isSpaceBooking = booking => {
  if (booking?.bookingSpace?.id) {
    return true;
  }
  return false;
};

const checkBearerExpired = function (bearer) {
  const decoded = decode(bearer);
  return decoded?.exp <= Math.round(Date.now() / 1000);
};

const compareUserKeyParams = function (userkeyToken, userStore, establishmentHash) {
  const userkeyTokenDecoded = decode(userkeyToken);

  const cliIdsArr = [];
  if (userkeyTokenDecoded) {
    if (userkeyTokenDecoded?.data?.stay && Object.keys(userkeyTokenDecoded.data.stay).length) {
      Object.keys(userkeyTokenDecoded.data.stay).forEach(key => {
        if (userkeyTokenDecoded.data.stay[key].cliId) {
          cliIdsArr.push(userkeyTokenDecoded.data.stay[key].cliId);
        }
      });
    }
  }

  if (cliIdsArr.length) {
    if (userStore.cliId) {
      const hasCliId = cliIdsArr.includes(userStore.cliId);
      return !hasCliId;
    } else {
      return true;
    }
  }
  return false;
};

const getHashId = function () {
  if (store.state.establishmentHash) {
    return store.state.establishmentHash;
  } else if (router.currentRoute?.value?.query?.id) {
    return router.currentRoute.value.query.id;
  }
};
/* it returns data only an expired bearer, if it has hashId, and checkExpiredBearers param is true. false in other case */
// TODO: in the future we could use this in no desktop PWA
const refreshingBearers = function (checkExpiredBearers) {
  if (checkExpiredBearers) {
    const hashId = getHashId();
    if (hashId) {
      let halfDayExpired = false;

      if (config.cmsApiHostCredentials?.bearer) {
        halfDayExpired = checkBearerPreviousExpirationOneHour(config.cmsApiHostCredentials.bearer);
        if (!halfDayExpired && config.botApiCredentials?.bearer) {
          halfDayExpired = checkBearerPreviousExpirationOneHour(config.botApiCredentials.bearer);
        }
      }

      let bearerExpired = false;
      if (config.cmsApiHostCredentials.bearer) {
        bearerExpired = checkBearerExpired(config.cmsApiHostCredentials.bearer);
        if (!bearerExpired && config.botApiCredentials.bearer) {
          bearerExpired = checkBearerExpired(config.botApiCredentials.bearer);
        }
      }
      // halfDayExpired = true; //TEST
      if (halfDayExpired || bearerExpired) {
        const data = {
          hashId: hashId,
          bearerExpired: bearerExpired,
          refresh: true
        };
        return data;
      }
    }
  }
  return false;
};
//bearer cms are 23 hours, and bot bearer are 24 hours
//to update in background
const checkBearerPreviousExpiration = function (bearer) {
  const decoded = decode(bearer);
  if (decoded && decoded.exp) {
    const currentTs = Math.round(Date.now() / 1000);
    if (decoded.exp <= currentTs + 75600) {
      //12 hours = 43200, 4 hours = 14400, 22 hours = 79200 , 21 hours = 75600, 20 hours = 72000
      return true;
    }
  }
  // return true; //TEST
  return false;
};

const checkBearerPreviousExpirationOneHour = function (bearer) {
  const decoded = decode(bearer);
  if (decoded && decoded.exp) {
    const currentTs = Math.round(Date.now() / 1000);
    if (decoded.exp <= currentTs + 3600) {
      //1hour = 3600
      return true;
    }
  }
  return false;
};

const isMultiCatalogueServiceType = function (typeString, service) {
  return (
    typeString === "restaurant" ||
    typeString === "sport" ||
    typeString === "covid19" ||
    typeString === "otherfacilities" ||
    typeString === "premiumservice" ||
    typeString === "pool" ||
    typeString === "balinese_bed" ||
    typeString === "gym" ||
    (service?.typeString === "grouping" &&
      service?.type &&
      (service?.type === 8 ||
        service?.type === 10 ||
        service?.type === 48 ||
        service?.type === 38 ||
        service?.type === 13 ||
        service?.type === 39))
  );
};

const noErrorKeys = [
  "LOGGED_USER_NEEDED",
  "AUTHENTICATION_FAILED",
  "NO_AVAILABLE_TURNS",
  "AppUser not found",
  "OVERLAPPED_STAY_NOT_ALLOWED",
  "USER_IS_NOT_CLIENT"
];

export function sendRequestErrorToSlack(response, toOtherChannel) {
  let status = "";
  let method = "";
  let url = "";
  let errorText = "";
  const date = moment().format("DD-MM-YYYY HH:mm:ss");
  const establishmentId = store.state.establishment.id;
  const establishmentHash = store.state.establishmentHash;
  const platform = store.state.isDesktop === true ? "PWA DESKTOP" : "PWA MOBILE";
  const userUID = store.state.user ? store.state.user.uid : "undefined";
  let headers = "";
  const clusterToken = config.clusterApiCredentials.bearer;
  const managerApiToken = config.cmsApiHostCredentials.bearer;
  const userkey = store.state.user ? store.state.user.userKey : "undefined";
  const apikey = config.apiKey;

  const disabled = true;
  const testingSlack = false;

  const environment = import.meta.env.NODE_ENV;

  if (disabled || (!testingSlack && environment !== "production")) {
    return;
  }

  if (response) {
    status = response.status;
    method = response.config ? response.config.method : "";
    url = response.config ? response.config.url : "";
    errorText = response.data?.errors ? response.data.errors : [];
    headers = response.config && response.config.headers ? JSON.stringify(headers) : "-";

    if (errorText && typeof errorText.toString === "function") {
      errorText = errorText.toString();
    }
  }

  const slackBody = {
    blocks: [
      {
        type: "divider"
      },
      {
        type: "context",
        elements: [
          {
            type: "image",
            image_url: "https://api.slack.com/img/blocks/bkb_template_images/notificationsWarningIcon.png",
            alt_text: "notifications warning icon"
          },
          {
            type: "mrkdwn",
            text: "*Error in HTTP Request*"
          }
        ]
      },

      {
        type: "section",
        fields: [
          {
            type: "mrkdwn",
            text: "*Platform:* PWA"
          },
          {
            type: "mrkdwn",
            text: "*REST Method:* " + method
          },
          {
            type: "mrkdwn",
            text: "*Request status:* " + status
          },
          {
            type: "mrkdwn",
            text: "*Date:* " + date
          },
          {
            type: "mrkdwn",
            text: "*Platform:* " + platform
          },
          {
            type: "mrkdwn",
            text: "*Establishment Id:* " + establishmentId
          },
          {
            type: "mrkdwn",
            text: "*Establishment Hash:* " + establishmentHash
          },
          {
            type: "mrkdwn",
            text: "*User uid:* " + userUID
          },
          {
            type: "mrkdwn",
            text: "*Api Manager token:* " + managerApiToken
          },
          {
            type: "mrkdwn",
            text: "*User key:* " + userkey
          }
        ]
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "*Browser URL:* " + window.location.href
        }
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "*Request Headers:* " + headers
        }
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "*Request URL:* " + "`" + url + "`"
        }
      },
      {
        type: "section",
        text: {
          type: "plain_text",
          text: "Error: " + errorText
        }
      },
      {
        type: "divider"
      }
    ]
  };

  let urlChannel = "https://hooks.slack.com/services/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";

  if (toOtherChannel) {
    if (toOtherChannel === "errors") {
      urlChannel = "https://hooks.slack.com/services/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";
    } else if (toOtherChannel === "warnings") {
      urlChannel = "https://hooks.slack.com/services/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";
    }
  } else if (testingSlack && environment !== "production") {
    return;
  }

  if (!errorText || errorText === "" || noErrorKeys.some(v => errorText.includes(v))) {
    return;
  } else {
    axios({
      method: "post",
      url: urlChannel,
      data: JSON.stringify(slackBody),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      withCredentials: false
    })
      .then(function (response) {})
      .catch(function (error) {
        console.error(error);
      });
  }
}
const refreshRSCartStoreCache = function () {
  if (!store.state.roomServiceCartCache || Object.keys(store.state.roomServiceCartCache).length > 0) {
    store.commit(types.ROOM_SERVICE_CART_CACHE, {});
  }
};
const getCurrentLanguage = function (languages) {
  let currentLanguage = store.state.interfaceLanguage ? store.state.interfaceLanguage : false;
  if (languages?.length > 0 && !currentLanguage) {
    const windowLanguage = window.navigator.language.substring(0, 2);
    const localeIsPermitted = languages.find(language => language.languageCode === windowLanguage);
    if (localeIsPermitted) {
      currentLanguage = windowLanguage;
    } else {
      const defaultLanguage = languages.find(language => language.default === true);
      currentLanguage = defaultLanguage?.languageCode ? defaultLanguage.languageCode : languages[0].languageCode;
    }
  }
  return currentLanguage;
};
const validateURL = textval => {
  // eslint-disable-next-line
  const urlregex = /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  return urlregex.test(textval);
};

const validateEmail = email => {
  // eslint-disable-next-line
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const postMessageToReactNativeWebView = (url, param) => {
  const message = {
    type: "openExternalURL",
    url: url
  };
  try {
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
  } catch (e) {
    openWindow(url, param);
  }
};

const openWindow = (url, param) => {
  const isworking = window.open(url, param);
  return !!isworking;
};

const openExternalURL = (url, param) => {
  return isEmbedReact(store.state.appId) ? postMessageToReactNativeWebView(url) : openWindow(url, param);
};

const updateOpenLocation = url => {
  isEmbedReact(store.state.appId) ? postMessageToReactNativeWebView(url) : (window.open().location = url);
};

const previewLegalClause = clause => {
  const { policyContentType, pdfContent, urlContent, textContent } = clause;

  if (policyContentType === "pdf" && pdfContent) {
    const url = getPDFUrl(translate(pdfContent));

    updateOpenLocation(url);
  } else if (policyContentType === "url" && urlContent) {
    const url = translate(urlContent);
    updateOpenLocation(url);
  } else if (policyContentType === "text" && textContent) {
    const htmlContentUrl = getHTMLContentUrl(translate(textContent));
    if (isEmbedReact(store.state.appId)) {
      return {
        htmlContentUrl: htmlContentUrl,
        isTextHtml: true
      };
    } else {
      window.open().document.write(htmlContentUrl);
    }
  }
};

const validateURLWithoutProtocol = textval => {
  // eslint-disable-next-line
  const urlregex = /(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  return urlregex.test(textval);
};

const validatePhoneNumber = number => {
  // Allow the user to enter numbers, +, -, whitespace and ()
  // eslint-disable-next-line
  const reg = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
  return reg.test(number);
};
const isCorrectAppId = () => {
  if (!window.location.href) {
    return true;
  }
  const urlObject = new URL(window.location.href);
  const token = urlObject?.searchParams?.get("token") ?? urlObject?.searchParams?.get("urlToken");
  if (token) {
    const decoded = decode(token);
    const idToCall = store.state.isLobby ? config.lobbyAppId : config.appId;
    if (decoded?.data?.appId && idToCall && decoded?.data?.appId != idToCall) {
      return false;
    }
  }
  return true;
};

const validatePositiveInteger = number => {
  return number && Math.sign(parseInt(number, 10)) === 1;
};

const paymentMethods = {
  PAYMENT_METHOD_CREDIT_CARD: "creditCard",
  PAYMENT_METHOD_CHARGE_ROOM: "chargeInRoom",
  PAYMENT_METHOD_CASH: "cash",
  PAYMENT_METHOD_ONLINE: "online",
  PAYMENT_METHOD_APPLE_PAY: "applePay",
  PAYMENT_METHOD_GOOGLE_PAY: "googlePay",
  PAYMENT_METHOD_LINK: "link"
};

const paymentStatus = {
  PAYMENT_STATUS_PENDING: "pending",
  PAYMENT_STATUS_PAID: "paid"
};

const mapLoginPmsResponse = obj => {
  const fieldsArray = [];
  for (var id in obj) {
    if (obj[id].hidden !== true) {
      let defaultValue = obj[id].default;
      if (!defaultValue) {
        defaultValue = "";
      }
      const field = {
        key: obj[id].key,
        required: obj[id].required,
        type: obj[id].type,
        ref: id,
        value: defaultValue,
        hidden: obj[id].hidden
      };
      fieldsArray.push(field);
    }
  }
  return fieldsArray;
};

const getLocale = () => {
  let locale = "en";
  if (window.navigator.language) {
    locale = window.navigator.language.substring(0, 2);
  } else if (this.user.locale) {
    locale = this.user.locale;
  }
  return locale;
};

const getServiceTimetableServeNow = (service, nowS) => {
  if (!service || !service.serviceTimetable) {
    return false;
  }

  const now = moment(nowS);
  const dayOfWeek = now.locale("en").format("dddd").toLowerCase();
  const timetablemix = getserviceTimetableAndExceptional(service.serviceTimetable, service.serviceTimetableExceptions);
  return timetablemix[dayOfWeek];
};

const isServiceOpenNowServe = (service, nowS) => {
  if (!service || service.temporaryClosed) {
    return false;
  }
  const timetable = getServiceTimetableServeNow(service, nowS);

  if (!timetable) {
    return false;
  }

  let isOpen = false;
  const now = moment(nowS);
  timetable.forEach(function (slot) {
    if (!isOpen) {
      const startsString = slot.starts;
      const endsString = slot.ends;
      const startsComponents = startsString.split(":");
      const endsComponents = endsString.split(":");

      const startTime = moment(nowS).set("hour", startsComponents[0]).set("minutes", startsComponents[1]);
      const endTime = moment(nowS).set("hour", endsComponents[0]).set("minutes", endsComponents[1]);

      let serviceClosesTomorrow = false;
      if (endTime.isBefore(startTime)) {
        endTime.add(1, "days");
        serviceClosesTomorrow = true;
      }
      isOpen =
        (startTime.isSameOrBefore(now) && endTime.isAfter(now)) ||
        (serviceClosesTomorrow && startTime.isSameOrBefore(now));
    }
  });
  return isOpen;
};

const isCmsMode = () => {
  return window.location.search.indexOf("mode=cms") !== -1;
};

const isIOS = () => /iphone|ipod|ipad/i.test(window.navigator.userAgent);

export {
  checkStr,
  checkStrTrim,
  getImageUrl,
  filterNotificationCount,
  checkEmptyTranslations,
  getPDFUrl,
  getHTMLContentUrl,
  getImageUrlField,
  getString,
  getStringNoEmpty,
  translate,
  translateEnglishOrDefaultForced,
  getItemUrl,
  getServiceType,
  moduleForServiceType,
  getServiceTypeId,
  showRequestFunnel,
  urlB64ToUint8Array,
  logScreen,
  logECommerceScreen,
  logVirtualPage,
  logBackButton,
  logExternalUrl,
  logGTMEvent,
  logGTMFrontEvent,
  categoryGTMforServiceType,
  isLogged,
  getUserStatus,
  logProductType,
  LogEstablishment,
  logChain,
  logUserID,
  capitalized,
  getImageFromService,
  findService,
  serviceTypes,
  deleteYearFromMoment,
  getModuleName,
  getEventById,
  enterToEvent,
  getUpdateEvent,
  validateEmail,
  openExternalURL,
  previewLegalClause,
  validatePhoneNumber,
  validatePositiveInteger,
  deviceDetector,
  sendDataStatusAlert,
  datesFilter,
  setCurrencyToPrice,
  checkEventPast,
  isEmbedPWA,
  isRiuApp,
  showCatalogue,
  hasPrice,
  // signJWT,
  getCurrencyDataSymbol,
  refreshRSCartStoreCache,
  getCurrentLanguage,
  isFullEmbedPWA,
  isEmbedReact,
  couldBeNativeEmbeddedPWA,
  isEmbeddedPWAByAppId,
  isUrlTokenAppId,
  isCorrectAppId,
  checkBearerExpired,
  compareUserKeyParams,
  getHashId,
  refreshingBearers,
  checkBearerPreviousExpiration,
  checkBearerPreviousExpirationOneHour,
  checkHours,
  isMultiCatalogueServiceType,
  getStatuswelcomeShowVisible,
  getmapResponse,
  convertHexToRGBA,
  getserviceTimetableAndExceptional,
  isSpaceOrderBookingUserData,
  isSpaceBooking,
  validateURL,
  validateURLWithoutProtocol,
  dayFirstLetter,
  paymentMethods,
  paymentStatus,
  mapLoginPmsResponse,
  getLocale,
  getNameTranslatable,
  getServiceTimetableServeNow,
  isServiceOpenNowServe,
  isMonoHotelApp,
  isCmsMode,
  isIOS
};
